@import '../../scss/variables';
@import '../../scss/shared';

.sbs-panel {
  @extend .normal-font;

  .action-list {
    padding: $small-space 0;
  }

  .list-item {
    border-bottom: $default-border;
    margin-bottom: $small-space;
  }

  .product {
    font-weight: $font-bold;
  }

  .date-time {
    color: $text-secondary-color;
    font-weight: $font-bold;
  }

  .sbs-type {
    color: $albelli-color;
    font-weight: $font-bold;
  }

  .sbs-text {
    @extend .big-font;
  }
}
