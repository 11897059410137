@import '../scss/variables';

.transform-black-blue {
  // Change colour of image from black to blue
  filter: brightness(0.1) invert(0.35) sepia(0.9) hue-rotate(170deg) saturate(800%);
}

.timeline.bo-grid {
  padding-left: $cell-space;
  padding-right: $cell-space;
  padding-bottom: $small-space;

  thead th {
    padding-bottom: $small-space;
  }

  thead th:nth-child(2) {
    padding-bottom: 7px;
  }

  tbody {
    border-top: 2px solid #374b59;
  }

  td {
    border: 0;
    padding-top: $small-space;
    padding-bottom: $small-space;
  }

  // icon column
  td:first-child {
    padding-right: 0;

    button {
      margin-top: -0.125rem;
    }
  }

  // wrap comments
  td:last-child {
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
  }

  .clickable {
    cursor: pointer;
  }

  $border-width: 1px;

  :not(.ticket-row) + .ticket-row td:not(:first-child),
  .ticket-row + :not(.ticket-row) td:not(:first-child) {
    border-top: $border-width solid $border-color;
  }

  .ticket-row {
    &:last-child td:not(:first-child) {
      border-bottom: $border-width solid $border-color;
    }

    td:not(:first-child) {
      background: #eee;
    }

    td:nth-child(2) {
      border-left: $border-width solid $border-color;
    }

    // Border with after element, because otherwise there is an overflow
    td:last-child {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: $border-width;
        background: $border-color;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}
