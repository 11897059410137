.pudo-info-title {
    font-weight: bolder;
    font-size: medium;
    margin-left: 4px;
    margin-right: 4px;
    display: inline;
}

.pudo-info-address {
    color: #777;
    margin-bottom: 0px;
}

.pudo-info-city {
    color: #777;
    margin-bottom: 0px;
    margin-top: 0px;
}

.pudo-info-box {
    border: 2px solid #003952;
    background: linear-gradient(0deg, #f4f6f8, #f4f6f8), #ffffff;
    margin: 10px;
    padding: 10px;
}

.pudo-info-hours {
 margin-top: 0.5rem;
}