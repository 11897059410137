@import '../scss/variables';

.finalizer-logs {
  &.bo-grid {
    td:first-child {
      min-width: 210px;
    }

    td:last-child {
      white-space: normal;
      overflow: auto;
      text-overflow: unset;
    }
  }
}
