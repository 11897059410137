@import '../../scss/shared.scss';

%cell {
  border: 0 none;
  padding: $cell-space $big-space $cell-space 0;
  white-space: nowrap;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    padding-right: 0;
    width: 100%;
  }
}

%cell-thin {
  padding: $cell-space $cell-space $cell-space 0;
}


.bo-grid {
  padding-bottom: 0;

  .wrap-normal{
    white-space: normal !important;
    overflow-wrap: break-word;
  }

  &.flex {
    width: auto;
  }

  &.no-border {
    border: 0 none;

    .table td {
      border: 0 none;
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.all-eq {
    th,
    td {
      width: auto;

      &:last-child,
      &:first-child {
        width: auto;
      }
    }
  }

  &.thin {
    thead th {
      @extend %cell-thin;
    }

    td {
      @extend %cell-thin;
    }
  }

  &.last-column-status {
    td:last-child {
      padding-top: 8px;
    }
  }

  &.foldable {
    padding: $small-space 0 0 0;

    .table {
      th:last-child,
      td:last-child {
        padding-right: $default-space;
      }

      .toggle-details {
        cursor: pointer;
        padding: 9px 2px 0 0;
        text-align: right;
        width: 32px;
      }

      .extra-details > td {
        padding: 0 $default-space $cell-space;
      }
    }
  }

  .table {
    margin: 0;

    th {
      @extend %cell;
      @extend .normal-font;
      padding-top: 0;
      padding-bottom: 0;
    }

    td {
      @extend %cell;
      border-top: 1px $border-color solid;
      font-size: $font-size-big;
      line-height: 24px;
    }

    tr {
      &:first-child {
        td {
          border: 0 none;
        }
      }
    }

    .extra-details > td {
      border: 0 none;
      padding: 0 0 $cell-space;
    }
  }
}

.bo-grid-vertical {
  &.wide {
    width: 100%;
  }

  tr {
    vertical-align: top;
  }

  th {
    padding: 0 18px $cell-space 0;
  }

  td {
    padding-bottom: $cell-space;
  }

  .delimiter {
    th {
      padding: 0;
    }
  }
}
