@import '~bootstrap/scss/bootstrap';
@import './variables';
@import './shared';
@import './forms';

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0 0 2.5rem 0;
  padding: 0;
  background-color: $bg-color-light;
  font-family: $font-family;
  font-size: $font-size-normal;
  color: $text-color;
  overflow: auto;
  height: calc(100% - 2.5rem);
  min-width: 1200px;
}

a {
  font-weight: $font-bold;
  color: $link-color;

  &.external {
    background-position: center right;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAA0UlEQVQoU8WSsRHCMBAE7xSRQQkEFICoADqgBByQOzSOHHkcugPogAaYgQaMKIESTAN6xgKMBAZCPtJobvX39yJ+VWKmoMhdNoLCjl+ZxAxBewLZb3WWSyI1C0DW7zAj5Hrj7n2N5YxYVTWIElYdArCHEzJdPwFG7nEHpUdpDih0CAUd7l2bBqLmn6HWkmezmbHQ526oC8jMwNkF8AGqMkCdvSDGgBjkE5f295keyTS7UrL/B3Tb0xbC2yI7S+YgY8+eWUBsGXyVV1DkAqr4EcwVNmF/tsbmDlEAAAAASUVORK5CYII=');
    padding-right: 15px;
  }

  &.attention {
    color: $albelli-color;
  }
}

mark {
  padding: 0;
  background-color: $mark-color;
}

h1 {
  font-size: $font-size-huge;
  font-weight: $font-bold;
}

h2 {
  @extend .big-font;
  font-weight: $font-bold;

  &.attention {
    color: $albelli-color;
  }
}

h3 {
  @extend .normal-font;
  font-weight: $font-bold;
}

b {
  font-weight: $font-bold;
}

.button {
  @extend .btn;
  @extend .big-font;

  background-color: $button-color;
  border-color: $button-color;
  border-radius: $radius;
  min-width: 60px;
  padding: 8px 12px;
  color: $text-color-light;
  font-weight: $font-bold;

  &.medium {
    @extend .normal-font;
    padding: 5px 10px;
  }

  &:hover:disabled {
    background-color: $button-color;
  }

  &:hover,
  &:active {
    color: $text-color-light;
    background-color: $button-color-hover;
  }

  &.orange {
    background-color: $albelli-color;
    padding: $tiny-space $tiny-space * 2;
    border: 0 none;
    border-radius: 3px;

    &:hover:not(.not-clickable) {
      background-color: $albelli-color-hovered;
    }

    &:disabled {
      background-color: $button-color-disabled;
    }
  }
}

button {
  @extend .button;
}

label {
  font-weight: $font-bold;
}

.badge {
  @extend .normal-font;
  font-weight: $font-bold;
  padding: 0 9px 0;
  margin: $tiny-space 0;
  color: $text-color-light;
  height: $badge-height;
  border-radius: $badge-height / 2;

  &.with-quantity {
    position: relative;
    padding-right: 30px;
  }

  .quantity {
    position: absolute;
    top: 2.5px;
    right: 2.5px;
    background-color: $panel-bg-color;
    line-height: 19px;
    width: 19px;
    height: 19px;
    font-size: $font-size-small;
    font-weight: $font-bold;
    text-align: center;
    white-space: nowrap;
    border-radius: 50%;
    padding: 0;
    margin: 0;
  }

  .text {
    display: inline-block;
  }

  &.fixed-size {
    text-align: left;

    .text {
      min-width: 150px;
    }
  }

  &.track-and-trace {
    text-align: left;
    width: 140px;

    .text {
      min-width: 100px;
    }
  }

  &.success {
    background-color: $success-color;

    .quantity {
      color: $success-color;
    }
  }

  &.other {
    background-color: $other-color;

    .quantity {
      color: $other-color;
    }
  }

  &.waiting {
    background-color: $waiting-color;

    .quantity {
      color: $waiting-color;
    }
  }

  &.in-progress {
    background-color: $in-progress-color;

    .quantity {
      color: $in-progress-color;
    }
  }

  &.warning {
    background-color: $warning-color;

    .quantity {
      color: $warning-color;
    }
  }

  &.danger {
    background-color: $danger-color;

    .quantity {
      color: $danger-color;
    }
  }
}

.bo-container {
  background-color: $panel-bg-color;
  padding: $small-space $default-space;
  border: 1px $border-color solid;
  border-radius: $radius;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }

  &.header-row {
    padding: 0.5rem $default-space;
    margin: 0.5rem 0;
  }

  &.embedded {
    background-color: transparent;
    border: 0 none;
    padding: 0;

    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }

  img.image-zoomed {
    transform: scale(6);
    position: relative;
    left: 300px;
    z-index: 10;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  }
}

.popover {
  overflow-y: auto;
  max-height: 600px;
  max-width: 600px;
}

.bo-modal {
  .modal-header {
    padding: 11px 1rem;

    .modal-title {
      font-weight: $font-bold;
      font-size: $font-size-huge;
      line-height: $line-height-huge;
    }
  }

  .modal-footer {
    border: none;
    justify-content: flex-start;
  }
}

.success-color {
  color: $success-color;
}

.danger-color {
  color: $danger-color;
}

.warning-color {
  color: $warning-color;
}

.normal-weight {
  font-weight: $font-normal;
}

.bo-tooltip {
  display: inline-block;
  cursor: default;

  &.clickable {
    cursor: pointer;
  }

  img {
    vertical-align: text-bottom;
  }
}

.round-icon {
  display: inline-block;
  font-size: $font-size-tiny;
  font-weight: $font-bold;
  color: $text-color-light;
  background-color: $other-color;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  text-transform: capitalize;
  border-radius: 50%;
  overflow: hidden;
  cursor: default;

  &.small {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  &.small-6 {
    width: 3rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  &.size-6 {
    width: 3rem;
  }
}

.product-preview {
  width: 80px;
  margin-right: $cell-space;

  img {
    width: 100%;
  }
}

.order-list {
  .order-details {
    font-size: $font-size-normal;
    line-height: $line-height-big;
    background-color: $bg-color-light;

    .row {
      border-top: 1px $border-color solid;
      padding: $tiny-space * 2;
      margin: 0;
    }

    .col {
      padding: 0;
    }
  }
}

.wide-button {
  color: $button-color;
  background-color: $panel-bg-color;
  width: 100%;
  height: $button-height-fat;
  border: 1px solid $button-color;
}

.action-list {
  padding: 0.32rem $default-space;
  border-top: 0 none;

  &.slim {
    border: none;
    padding: 0;

    span.mr-3 {
      margin-right: $small-space !important;
    }
  }
}

.bo-widget {
  .notification {
    display: flex;
    border-bottom: $default-border;
    padding-bottom: $cell-space;
    margin-bottom: $cell-space;

    img {
      margin-right: $default-space;
    }
  }

  .create {
    padding-bottom: $cell-space;
    border-bottom: $default-border;
  }

  .action-list {
    padding-top: $cell-space;
    border-top: $default-border;
  }
}

.order-price {
  position: relative;

  .refunds-button {
    @extend .button;

    position: absolute;
    top: 0;
    right: 0;
  }
}

.order-structure {
  .form-check-label,
  input[type='checkbox']:not(:disabled) {
    cursor: pointer;
  }
}

.disabled {
  color: $button-color-disabled;
  cursor: default;
}

.lazy-container {
  text-align: center;

  .message {
    margin-top: $default-space;
    display: block;
  }
}

.bulkProgress {
  width: 100%;
  height: 50px;  
}
