.submit-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}

.cancel-submit-button {
  padding-right: 5px;
  background-color: #6c757d;
}

.spinner-side-panel-container{
  display: flex;
  justify-content: center;
  align-items: center
}

.spinner-side-panel {
  width: 6rem;
  height: 6rem;
  color:#6c757d !important;
}