@import '../scss/variables';

.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6),
  .tabset > input:nth-child(13):checked ~ .tab-panels > .tab-panel:nth-child(7) {
    display: block;
  }
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 15px 15px 25px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
  }
  
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 22px;
    height: 4px;
    background: #8d8d8d;
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: #003952;
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #003952;
  }
  
  .tabset > input:checked + label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
  }
  
  .tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
  }

  .tabset {
    max-width: 75em;
  }

  .response-container{
    margin: 1em 0em;    
    border: solid;
    border-width: thin;
    padding: 1em;
  }

  .response-row{    
    margin: 2px;
    padding: 2px;
  }
  
  .status-success{
    color: green;
    font-weight: bold;
  }

  .status-error{
    color:red;
    font-weight: bold;
  }

  .status-heading {
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 14px;
  }

  .response-container .row:first-child .status-heading {
    margin-top: 0;
  }

  .column-highlight{
    font-weight: bold;
  }

  .back-button {
    text-align: end
  }
  .export-csv {
    text-align: end
  }