$font-family: 'Segoe UI', Arial, Helvetica, sans-serif;

$bg-color-dark: #003952;
$bg-color-dark-hovered: #14485f;
$bg-color-light: #f4f6f8;
$button-color: #007ace;
$button-color-hover: #007acecc;
$button-color-disabled: #ccc;
$link-color: #007ace;
$text-color-light: #fff;
$text-color: #333;
$text-secondary-color: #999;
$border-color: #ccc;
$panel-title-bg-color: #0277bd;
$panel-bg-color: #fff;
$overlay-color: rgba(0, 0, 0, 0.5);
$mark-color: #ffea8a;

$danger-color: #cd051f;
$warning-color: #f57c23;
$waiting-color: #0a86d1;
$in-progress-color: #003952;
$success-color: #07761b;
$other-color: #555;

$albelli-color: #ff6a13;
$albelli-color-hovered: rgba(255, 106, 19, 0.8);
$resnap-color: #3e5aff;

$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-big: 16px; // 1rem
$font-size-huge: 18px;

$font-narrow: 100;
$font-normal: 400;
$font-bold: 600;

$tiny-space: 0.25rem; // 4px
$small-space: 0.625rem; // 10px
$cell-space: 0.75rem; // 12px
$default-space: 1.25rem; // 20px
$big-space: 2rem; // 32px

$line-height-normal: $default-space;
$line-height-big: $default-space * 1.1; // 22px
$line-height-huge: $default-space * 1.25; // 25px

$badge-height: 1.5rem; // 24px
$button-height: 2rem; // 32px
$button-height-fat: 2.5rem; // 40px
$header-height: 3.5rem; // 56px

$radius: 2px;

$animation-speed: 0.2s;

$default-border: 1px solid $border-color;

$panel-width: 500px;
