@import './variables';

.form-control.is-invalid {
  background-image: none;
}

input[type='text'].form-control,
select.form-control,
textarea.form-control {
  border: $default-border;
  border-radius: $radius;
  padding-left: 0.5rem;
}

input[type='checkbox'].form-check-input
{
  margin-left: 0rem;
}
label.form-check-label{
  margin-left: 1rem;
}

.search-container {
  padding: 0 $default-space;

  .panel {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap-reverse;

    .buttons {
      flex-shrink: 1;
      margin-bottom: $default-space;

      button {
        padding: 0.5rem 1rem;
        border: 0 none;

        &:not(:first-child) {
          margin-left: $default-space;
        }
      }

      a {
        border-radius: 0;
        color: $text-color-light;
        background-color: $button-color;
        line-height: $line-height-big;
        min-width: 110px;
        width: fit-content;
        text-align: center;
        font-weight: $font-bold;

        &:hover {
          background-color: $button-color-hover;
          color: $text-color-light;
        }
      }
    }

    .search-filter {
      flex-grow: 1;
    }
  }
}
