.refund-table-container {
  overflow-x: auto;
  margin: 1rem 0;
}

.toggle-history {
  margin-bottom: 1rem;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #ddd;
  cursor: pointer;

  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}

table {

  td,
  th {
    transition: width 0.2s ease-out;
  }
}

.refund-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
    text-align: right;
    border: 1px solid #ddd;

    &:first-child {
      text-align: left;
    }
  }

  th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;

    &.remaining {
      background-color: #e8f5e9;
    }

    &.history {
      background-color: #f5f5f5;
    }

    &.in-progress {
      background-color: #fff3e0;
    }

    .date {
      font-size: 0.8em;
      font-weight: normal;
    }

    .reason {
      font-size: 0.8em;
      font-style: italic;
    }
  }

  .total-row {
    font-weight: bold;
    background-color: #f8f9fa;
  }
}