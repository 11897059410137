@import '../scss/variables';

.refunds {
  .header-row {
    margin-bottom: 0;
  }

  .content {
    display: flex;

    .refund-form {
      margin-top: 0.5rem;
      width: $panel-width;

      .summary {
        border: 1px solid $button-color;
        padding: $small-space;
        display: flex;
        justify-content: space-between;

        .total {
          font-weight: $font-bold;
          align-items: center;
          flex-grow: 1;
        }

        button {
          align-self: flex-end;
        }
      }

      .validation-error {
        color: #dc3545;
      }
    }

    .refund-data {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      width: $panel-width;
    }

    .order-value-record {
      width: $panel-width;
      background-color: $panel-bg-color;
      padding: $default-space;
      border: $default-border;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;

      .product-code {
        display: flex;

      }
    }

    .order-price {
      width: $panel-width;
      background-color: $panel-bg-color;
      padding: $default-space;
      border: $default-border;
    }
  }
}

.order-value-record-thin {
  font-weight: 400;
}