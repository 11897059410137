@import './variables';

.big-font {
  line-height: $line-height-big;
  font-size: $font-size-big;
}

.normal-font {
  line-height: $line-height-normal;
  font-size: $font-size-normal;
}

.secondary-font {
  color: $text-secondary-color;
}

@mixin fade-out($delay: 5s) {
  animation: fadeOut $animation-speed;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.fade-out {
  @include fade-out();
}

@keyframes fadeOut {
  to {
    opacity: 0;
    height: 0;
    line-height: 0;
    padding: 0;
    margin: 0;
  }
  from {
    opacity: 1;
  }
}
