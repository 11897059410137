@import '../../scss/variables.scss';

%nav-link {
  height: $header-height;
  line-height: 32px;
  color: $text-color-light;
  font-size: $font-size-huge;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  border: 0 none;
  box-shadow: none;
  border-radius: 0;

  &:hover {
    color: $text-color-light;
    background-color: $bg-color-dark-hovered;
  }
}

.bo-layout-container {
  width: 100%;
  padding: $default-space;
  position: relative;
}

.bo-main-menu {
  background-color: $bg-color-dark;
  padding: 0;
  width: 100%;

  .navbar-toggler {
    @extend %nav-link;
  }

  .navbar-nav {
    .nav-link {
      @extend %nav-link;
      padding: 12px 24px;
      font-weight: 400;
    }

    .active {
      color: $text-color-light;
      background-color: $bg-color-dark-hovered;

      a {
        font-weight: $font-bold;
      }
    }
  }

  .title {
    @extend %nav-link;
    font-weight: $font-normal;
    padding: 12px 0;
    flex-grow: 1;
  }

  .logo {
    @extend %nav-link;
    padding: 10px 14px 0;
  }

  .right-menu.navbar-nav {
    flex-basis: 100%;
    justify-content: flex-end;

    .nav-item {
      .nav-link {
        font-size: $font-size-normal;
      }

      &:last-child {
        box-shadow: -1px 0 0 0 $text-color-light;
      }
    }
  }
}

.bo-footer {
  background-color: $bg-color-light;
  padding: 10px;
  box-shadow: 0px -1px 2px -1px $text-color;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
}
