.tab-block {
    display: flex;
    border-bottom: 2px solid #003952;
    margin-top: 0.5rem 
 }
  
  .tab-content {
    margin: 5px;
    padding: 8px;
  }
  
  .tab-bar-item {
    width: 33%;
    padding: 8px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  
  .tab-button.selected {
    background: linear-gradient(0deg, rgba(0, 122, 206, 0.1), rgba(0, 122, 206, 0.1)), #ffffff;
    border: 2px solid #003952;
    border-radius: 4px 4px 0px 0px;
  }
  
  .tab-button {
    background: linear-gradient(0deg, #f4f6f8, #f4f6f8), #ffffff;
    margin-right: 10px;
    border: 0px;
  }
  
  .tab-button-last {
    margin-right: 0px;
  }
  
  .tab-button-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .tab-button-date {
    font-style: normal;
    font-weight: 390;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  