@import '../../scss/variables';

.search-filter {
  margin-bottom: $default-space;

  .nav-link {
    border-radius: 0;
    color: $bg-color-dark;
    background-color: $panel-bg-color;
    margin-right: $default-space;
    margin-bottom: $default-space;
    line-height: $line-height-big;
    min-width: 110px;
    width: auto;
    text-align: center;

    &.active {
      font-weight: $font-bold;
      color: $text-color-light;
      background-color: $bg-color-dark;
    }

    &:hover {
      outline: 1px solid $bg-color-dark;
    }
  }

  .query {
    padding-right: 0;
    font-size: $font-size-huge;

    input[type='text'] {
      border: 1px solid $bg-color-dark;
      padding-right: 35px;
      font-weight: $font-bold;
      height: 48px;

      &:focus {
        box-shadow: 0 0 0 1px $button-color;
        border: 1px solid $button-color;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.625rem 0.375rem;
      min-width: 24px;
      font-weight: $font-normal;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }

      &.close {
        padding-right: 0.6rem;
      }
    }
  }
}
