table.playground {
    border-collapse: collapse; /* Remove default spacing */
    margin-bottom: 20px; 
  }

table.playground th, table.playground td {
    border: 1px solid #ddd;
    padding: 4px; 
    text-align: left;
  }

  table.playground thead, table.playground tfoot {
    text-align: center;
  }

  .summary {
    border: 1px solid #007ace;
    padding: 0.625rem;
    display: flex;
    justify-content: space-between;
}

.playground-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.playground-badge.active {
  background-color: #ff6a13;
  color: white;
}

.playground-badge.inactive {
  background-color: #6c757d;
  color: white;
}