.newCode {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.spinner {
  display: flex;
  justify-content: center;
  padding: 16px;
}