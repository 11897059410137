.redo-confirmation-container {
    margin-top: 0.5rem;
    font-size: 16px;
}

.redo-confirmation-title {
    margin-top: 1rem;
}

.redo-confirmation-product {
    margin-top: 1rem;
}

.redo-confirmation-delivery {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.redo-confirmation-cancel {
    margin: 10px;
    margin-right: 20px;
}