@import '../../scss/variables';

@keyframes slide {
  from {
    right: -$panel-width;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.bo-side-panel {
  hr {
    margin: $small-space 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $overlay-color;
    z-index: 1;
  }

  .bo-side-panel-container {
    position: fixed;
    top: 0;
    right: 0;
    width: $panel-width;
    height: 100vh;
    z-index: 1;
    background-color: $panel-bg-color;
    overflow-y: auto;
    animation: slide $animation-speed ease-out;

    .title {
      padding: 0 $default-space;
      font-weight: $font-bold;
      font-size: $font-size-huge;
      line-height: $header-height;
      box-sizing: border-box;
      margin-bottom: $small-space;
      color: $text-color-light;
      background-color: $panel-title-bg-color;

      .close {
        opacity: 1;
        line-height: $header-height;

        img {
          cursor: pointer;
        }
      }
    }

    .container-payload {
      padding: 0 $default-space;
    }
  }
}
